import { useDispatch, useSelector } from 'react-redux';
import { selectUserInfoData, setUserInfo } from '../../../reducers/userInfoReducer';
import { useFeatureToggle } from '../useFeatureToggle';
import { AccountType } from '@server/account-type';
import { useCallback } from 'react';
import { AI_QUESTIONS_LIMIT } from '@server/homepage';
import usePaywall from '../../dashboard/paywall/usePaywall';
import { PaywallState } from '../../dashboard/paywall/paywallTypes';

export const useLimitMessageCount = () => {
  const { technicalUsersHomepage } = useFeatureToggle();
  const { paywallState } = usePaywall();
  const userInfo = useSelector(selectUserInfoData);
  const dispatch = useDispatch();

  const isInTrial = paywallState === PaywallState.IN_TRIAL;
  const isFreeUser = userInfo?.account?.type === AccountType.Free && !isInTrial;
  const messagesCount = userInfo?.preferences?.freeAiQuestionsCount ?? AI_QUESTIONS_LIMIT;
  const hasMoreMessagesLeft = !!technicalUsersHomepage && isFreeUser && messagesCount !== 0;
  const keepPromptsOpen = technicalUsersHomepage && isFreeUser;

  const updateMessageCount = useCallback(() => {
    if (!technicalUsersHomepage || !isFreeUser) {
      return;
    }

    const preferences = {
      ...userInfo?.preferences,
      freeAiQuestionsCount:
        (userInfo?.preferences?.freeAiQuestionsCount
          ? userInfo?.preferences?.freeAiQuestionsCount
          : AI_QUESTIONS_LIMIT) - 1,
    };
    dispatch(setUserInfo({ ...userInfo, preferences }));
  }, [userInfo, dispatch, isFreeUser, technicalUsersHomepage]);

  return {
    messagesLeftCount: messagesCount,
    keepPromptsOpen,
    hasMoreMessagesLeft,
    updateMessageCount,
  };
};
